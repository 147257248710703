<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                           {{ $t('message.update_city') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit">{{ $t('message.save_and_close') }}</el-button>
                            <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="row">
            <div class="col-12 mt-2">
                <div class="modal-body">
                    <el-form ref="form" status-icon :model="form" :rules="rules" class="aticler_m">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item :label="columns.name.title" prop="name">
                                    <el-input v-model="form.name" autocomplete="off"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="columns.state_id.title" prop="state_id">
                                    <el-select v-model="form.state_id" :placeholder="columns.state_id.title"
                                        filterable clearable>
                                        <el-option v-for="item in states" :key="item.id + item.name" :label="item.name"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex'
    import form from '@/utils/mixins/form';
    export default {
        mixins: [form],
        props: {
            reloadModel: {type: Boolean, default: true}
        },
        data(){
            return {}
        },
        watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadModel()
                    }
                },
                deep: true
            },
        },
        created(){},
        async mounted(){
            if (this.states && this.states.length === 0)
                await  this.loadStates();
        },
        computed: {
            ...mapGetters({
                rules: 'cities/rules',
                model: 'cities/model',
                columns: 'cities/columns',
                states: "states/list",
            })
        },
        methods:{
            ...mapActions({
                save: 'cities/update',
                loadStates: "states/index",
            }),
            loadModel(){
                this.form = JSON.parse( JSON.stringify( this.model ));
            },
            submit(close = true){
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$emit('c-close',{drawer: 'drawerUpdate'});
                                this.$alert(res);
                                // this.parent().listChanged();
                                this.$refs['form'].resetFields();
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('c-close',{drawer: 'drawerUpdate'});
            },
        }
    }

</script>
